var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[(_vm.permisos.cartera_ini)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.capturados'),
          icon: 'tim-icons icon-paper',
          path: '/capturados'
        }}}):_vm._e(),(_vm.permisos.cartera_fin)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.proceso'),
          icon: 'tim-icons icon-notes',
          path: '/proceso'
        }}}):_vm._e(),(_vm.permisos.admin)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.users'),
          icon: 'tim-icons icon-single-02',
          path: '/usuarios'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.captados'),
          icon: 'tim-icons icon-wallet-43',
          path: '/captados'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.validados'),
          icon: 'tim-icons icon-check-2',
          path: '/validados'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.comerciales'),
          icon: 'tim-icons icon-credit-card',
          path: '/comerciales'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.numeros'),
          icon: 'tim-icons icon-tablet-2',
          path: '/numeros'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.mapa'),
          icon: 'tim-icons icon-square-pin',
          path: '/mapa'
        }}}),_c('sidebar-item',{staticStyle:{"min-height":"100px"}})],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }